import React from "react"
import { useQuery } from "react-query"
import { jsx, css } from "@emotion/core"

import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts"

import SEO from "../components/seo"

function getMonday(d) {
  d = new Date(d)
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1)
  return +new Date(d.setDate(diff))
}

const monday = getMonday(new Date())

const url =
  process.env.NODE_ENV === "development"
    ? `http://localhost:8002/stats/${monday}`
    : `https://just-some-statistik.herokuapp.com/stats/${monday}`

export default function App() {
  const { isLoading, error, data } = useQuery("repoData", () =>
    fetch(url).then(res => res.json())
  )

  if (isLoading) return "Loading..."

  if (error) return "An error has occurred: " + error.message

  const chartData = data.visitsThisWeek.map(d => {
    return { date: d.date, amount: d.amount }
  })

  const {
    totalVisits,
    totalUniqueVisits,
    totalVisitsThisWeek,
    topCountries,
  } = data

  return (
    <div
      css={css`
        padding: 50px;
        font-family: "Lora";
        p {
          max-width: 600px;
        }
      `}
    >
      <SEO title="Analytics" />
      <h2
        css={css`
          font-family: "Ramabhadra";
          font-size: 30px;
          font-weight: normal;
          line-height: 30px;
          padding: 0;
          margin: 0;
          color: black;
          text-decoration: none;
        `}
      >
        Analytics
      </h2>

      <p>
        Hey, welcome to my blog analytics. These numbers are all collected
        through a self-written analytics server. It's less advanced than it
        sounds, but for the most part it seems to work. I'm sure the stats of
        this blog are embarassingly low, so tell your friends to visit my blog
        :)
      </p>

      <div
        css={css`
          float: left;
          width: 100%;
        `}
      >
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            margin={{ top: 25, right: 0, left: -15, bottom: 30 }}
            data={chartData}
          >
            <Line type="monotone" dataKey="amount" stroke="#506b4f" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="date" angle={90} dx={20} />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div
        css={css`
          float: left;
          width: 100%;
          font-family: "Lora";
          font-size: 30px;
          font-weight: normal;
          line-height: 30px;
          h3 {
            font-size: 14px;
            font-family: "Ramabhadra";
          }
          ul {
            margin: 0px;
          }
          li {
            font-size: 14px;
          }
        `}
      >
        <div
          css={css`
            float: left;
            width: 100%;
          `}
        >
          <div
            css={css`
              float: left;
              width: 150px;
              margin-right: 50px;
            `}
          >
            <h3>Total visits</h3>
            {totalVisits}
          </div>
          <div
            css={css`
              float: left;
              width: 150px;
            `}
          >
            <h3>Total unique visits</h3> {totalUniqueVisits}
          </div>
        </div>
        <div
          css={css`
            float: left;
            width: 100%;
          `}
        >
          <div
            css={css`
              float: left;
              width: 150px;
              margin-right: 50px;
            `}
          >
            <h3>Total visits this week</h3> {totalVisitsThisWeek}
          </div>
          <div
            css={css`
              float: left;
              width: 150px;
            `}
          >
            <h3>Top countries</h3>
            <ul>
              {topCountries.map(item => {
                return (
                  <li key={item.country}>
                    {item.country}: {item.amount}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
